<template>
  <div class="home-page">
    <ContactInfo></ContactInfo>
    <div class="top-div">
      <img class="bag" src="../../assets/images/page-background.png" alt="" />
      <div class="big-title">
        <p v-scroll="'animate__bounceIn'" class="Rem0_5 animate__animated">
          景翔云体系,为您的体系保驾护航
        </p>
        <p v-scroll="'animate__bounceInDown'" class="Rem0_25 animate__animated">
          WELCOME TO LOGIN THE JEAMSWAN SYSTEM ESCORT YOUR SYSTEM
        </p>
        <div>
          <el-button
            class="animate__animated"
            v-scroll="'animate__fadeIn'"
            @click="$router.push('./trial-use')"
            >申请免费使用</el-button
          >
          <!-- <el-button @click="skip">操作说明</el-button> -->
        </div>
      </div>
    </div>
    <div class="data-list2">
      <p v-scroll="'animate__fadeInLeftBig'" class="Rem0_5 animate__animated">
        云体系能做什么
      </p>
      <div class="data-item">
        <div
          v-for="(item, index) in dataList2"
          :key="index"
          v-scroll="'animate__fadeInRightBig'"
          class="animate__animated"
        >
          <img @click="openWindow" :src="item.img" alt="" />
          <p class="Rem0_25">{{ item.p1 }}</p>
          <p class="Rem0_16">{{ item.p2 }}</p>
        </div>
      </div>
    </div>
    <div class="three-div">
      <p v-scroll="'animate__fadeInDown'" class="Rem0_5 animate__animated">
        多生态、多场景的软件开发平台
      </p>
      <div class="carousel">
        <div class="icon">
          <div
            class="animate__animated"
            v-scroll="'animate__rollIn'"
            @click="clickCarousel(index)"
            :class="{ carouselActive: index == carouselIndex }"
            v-for="(i, index) in dataList3"
            :key="index"
          >
            <img :src="i.img" alt="" /><span style="white-space: pre-wrap">{{
              i.name
            }}</span>
          </div>
        </div>
        <div class="png">
          <el-carousel
            class="animate__animated"
            v-scroll="'animate__fadeInDownBig'"
            trigger="click"
            ref="showImgindex"
            height="5rem"
            @change="changeDataList3Img"
          >
            <el-carousel-item
              v-for="(item, index) in dataList3Img"
              :key="index"
            >
              <img
                class="animate__animated"
                v-scroll="'animate__fadeIn'"
                :src="item.img"
                @click.stop="topage(index)"
              />
              <!-- <p class="animate__animated" v-scroll="'animate__bounceIn'">
                {{ item.title }}
              </p> -->
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="video-box">
      <p v-scroll="'animate__fadeInDown'" class="Rem0_5 animate__animated">
        知识视频库
      </p>
      <div class="videoItem">
        <div
          v-for="(item, index) in videoList"
          :key="index"
          v-scroll="'animate__rollIn'"
          class="animate__animated"
        >
          <img :src="item.bagroundImg" alt="" />
          <img
            style="cursor: pointer"
            src="../../assets/images/off.png"
            alt=""
            @click="openVideo(item.src)"
          />
          <p>{{ item.name }}</p>
          <p>{{ item.detail }}</p>
        </div>
      </div>
    </div>
    <div class="four-div">
      <div class="p">
        <p v-scroll="'animate__fadeInLeftBig'" class="Rem0_5 animate__animated">
          景翔书籍
        </p>
        <el-button
          @click="$router.push('./book-all-detail')"
          v-scroll="'animate__flipInX'"
          class="animate__animated"
          >查看更多<i class="el-icon-right"></i
        ></el-button>
      </div>
      <div
        class="back_add animate__animated"
        v-scroll="'animate__fadeInRightBig'"
      >
        <div class="threeImg">
          <div class="Containt">
            <div
              class="iconleft"
              v-on:mouseout="move()"
              v-on:mouseover="stopmove()"
              @click="youhua"
            >
              <i class="el-icon-arrow-left"></i>
            </div>
            <ul
              :style="{ left: calleft + 'px' }"
              v-on:mouseover="stopmove()"
              v-on:mouseout="move()"
            >
              <li
                v-for="(item, index) in superurl"
                :key="index"
                @click="toBookDetailPage(item.p1)"
              >
                <img :src="item.img" />
                <p class="p1">{{ item.p1 }}</p>
                <p class="p2">{{ item.p2 }}</p>
              </li>
            </ul>
            <div
              class="iconright"
              v-on:mouseout="move()"
              v-on:mouseover="stopmove()"
              @click="zuohua"
            >
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
        <div class="line">
          <span
            v-for="(i, index) in 10"
            :key="index"
            :class="{ activeLine: lineIndex == index }"
          ></span>
        </div>
      </div>
    </div>
    <div class="industry-consulting">
      <p v-scroll="'animate__bounceInDown'" class="Rem0_5 animate__animated">
        行业资讯
      </p>
      <div class="contain">
        <div class="left animate__animated" v-scroll="'animate__fadeInLeftBig'">
          <div class="block">
            <el-carousel
              trigger="click"
              height="470px"
              @change="changeCarousel"
            >
              <el-carousel-item
                v-for="(item, index) in consultList"
                :key="index"
              >
                <img :src="item.imageUrl" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <p>{{ consultName }}</p>
        </div>
        <div class="right">
          <div class="title animate__animated" v-scroll="'animate__flipInX'">
            <span>更多新闻</span>
            <el-button>查看更多<i class="el-icon-right"></i></el-button>
          </div>
          <el-carousel class="carouselName div-box" height="150px">
            <el-carousel-item v-for="(item, index) in consultList" :key="index">
              <h3 class="small">{{ item.title }}</h3>
            </el-carousel-item>
          </el-carousel>
          <div
            class="div-box animate__animated"
            v-scroll="'animate__fadeInRightBig'"
          >
            <div v-for="(item, index) in consultList" :key="index">
              <div class="imgBox">
                <img :src="item.imageUrl" alt="" />
              </div>
              <div class="text">
                <div>
                  <span>{{ item.title }}</span>
                  <span>{{ item.time ? item.time.split("T")[0] : "" }}</span>
                </div>
                <p>
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="five-div">
      <img
        class="animate__animated"
        v-scroll="'animate__fadeInLeftBig'"
        @click="openWindow"
        src="../../assets/images/onepage6.png"
        alt=""
      />
      <div class="Rem0_5 animate__fadeInUpBig" v-scroll="'animate__animated'">
        <p>合作伙伴</p>
        <!-- <img src="../../assets/images/onepage9.png" alt="" /> -->
        <el-button @click="$router.push('./trial-use')">联系我们</el-button>
      </div>
      <img
        class="animate__animated"
        v-scroll="'animate__fadeInRightBig'"
        @click="openWindow"
        src="../../assets/images/onepage7.png"
        alt=""
      />
    </div>
    <div class="contact-us">
      <div class="phone animate__animated" v-scroll="'animate__fadeIn'">
        <div>
          <p>联系我们</p>
          <p>CONTACT US</p>
        </div>
        <div>
          <span>服务热线：</span>
          <span>020-39984322</span>
        </div>
      </div>

      <div class="other-info animate__animated" v-scroll="'animate__bounceIn'">
        <div>
          <p>地址：广州市番禺区洛溪新城105国道97号东基商务大楼6楼</p>
          <p>电话：020-39984322 / 39984311</p>
          <p>传真：020-39984300</p>
          <p class="infoBox">
            <img src="../../assets/images/logo1.png" alt="" />
            <span @click="$router.push('/home-page')"
              >景翔信息科技有限公司</span
            >
            <a href="http://beian.miit.gov.cn" target="_blank"
              >(粤ICP备2021114949号-4)</a
            >
          </p>
        </div>

        <div>
          <p>手机：13560068664（微信同号）</p>
          <p>E-mail：mail@jeansawn.com</p>
          <p>网址：www.jeansawn.com</p>
          <p>©2024 广州市景翔信息科技有限公司&nbsp;&nbsp;版权所有</p>
        </div>
        <div class="jpg">
          <img
            @click="openWindow"
            src="../../assets/newhome/jingxiang.jpg"
            alt=""
          />
          <p>扫一扫关注我们</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "animate.css";
import animateScroll from "../../modules/AnimateScroll.js";
import { baseURL, get } from "../../api/http";
import ContactInfo from "../../components/public/ContactInfo.vue";
export default {
  components: { ContactInfo },
  data() {
    return {
      dataList1: [
        {
          img: require("../../assets/images/pageitem1.png"),
          name: "管理软件",
          text: "通过软件建立公司与公司之间的 信息互联通过软件建立公司与公司之间的 信息互联，，为项目实操工作人员 提供一站式综合操纵平台，高效...",
        },
        {
          img: require("../../assets/images/pageitem2.png"),
          name: "体系搭建",
          text: "通过软件建立公司与公司之间的 信息互联，为项目实操工作人员 提供一站式综合操纵平台，高效...",
        },
        {
          img: require("../../assets/images/pageitem3.png"),
          name: "体系维护",
          text: "通过软件建立公司与公司之间的 信息互联，为项目实操工作人员 提供一站式综合操纵平台，高效...",
        },
        {
          img: require("../../assets/images/pageitem4.png"),
          name: "体系落地",
          text: "通过软件建立公司与公司之间的 信息互联，为项目实操工作人员 提供一站式综合操纵平台，高效...",
        },
        {
          img: require("../../assets/images/pageitem5.png"),
          name: "培训课程",
          text: "通过软件建立公司与公司之间的 信息互联，为项目实操工作人员 提供一站式综合操纵平台，高效...",
        },
      ],
      dataList2: [
        {
          img: require("../../assets/images/pageitem1-1.png"),
          p1: "降低体系维护成本",
          p2: "利用系统降低人员门槛，提高体系 维护效率和有效性",
        },
        {
          img: require("../../assets/images/pageitem1-2.png"),
          p1: "为第三方审核保驾护航",
          p2: "提供1对1专业咨询服务，保证通过第三方认证审核",
        },
        {
          img: require("../../assets/images/pageitem1-3.png"),
          p1: "体系检查自动化",
          p2: "策划审核计划后，自动匹配审核员，自动生成检查表，整改流程自动化",
        },
        {
          img: require("../../assets/images/pageitem1-4.png"),
          p1: "专业顾问团队指导",
          p2: "汇集一批行业资深实践型管理专家，针对企业具体问题具体分析",
        },
        {
          img: require("../../assets/images/pageitem1-5.png"),
          p1: "百款课程随意选",
          p2: "打磨了上百门适合企业不同发展需求的课程，企业可根据需求随意挑选",
        },
        {
          img: require("../../assets/images/pageitem1-6.png"),
          p1: "零成本打造学习平台",
          p2: "在系统上实现学习规划、在线学习、在线考试、成绩管理",
        },
      ],
      data3Index: 0,
      carouselIndex: 0,
      dataList3: [
        // {
        //   img: require("../../assets/images/pageicon1.png"),
        //   name: "体系搭建",
        //   path: "/system-build",
        // },
        // {
        //   img: require("../../assets/images/pageicon2.png"),
        //   name: "体系委托",
        //   path: "/system-depute",
        // },

        // {
        //   img: require("../../assets/images/pageicon3.png"),
        //   name: "体系落地",
        //   path: "/system-to-ground",
        // },
        // {
        //   img: require("../../assets/images/pageicon4.png"),
        //   name: "过程优化",
        //   path: "/process-optimize",
        // },

        // {
        //   img: require("../../assets/images/pageicon5.png"),
        //   name: "考核标准",
        // },
        {
          img: require("../../assets/images/pageicon6.png"),
          name: "管理体系\n数字化转型",
          path: "/num-transform",
        },
        {
          img: require("../../assets/images/pageicon2.png"),
          name: "管理体系\n委托维护",
          path: "/depute-maintena",
        },
        {
          img: require("../../assets/images/pageicon3.png"),
          name: "体系数字化\n落地",
          path: "/num-to-ground",
        },
        {
          img: require("../../assets/images/pageicon8.png"),
          name: "现代流程优化",
          path: "/modern-p-o",
        },
        {
          img: require("../../assets/images/pageicon5.png"),
          name: "业绩考核\n标准制定",
          path: "/exam-customized",
        },
        {
          img: require("../../assets/images/pageicon7.png"),
          name: "题库服务",
          path: "/que-bank-system",
        },
      ],
      dataList3ImgObj: {
        img: require("../../assets/images/home体系搭建.png"),
        title: "",
      },
      dataList3Img: [
        {
          img: require("../../assets/images/home体系搭建.png"),
          title: "",
        },
        {
          img: require("../../assets/images/home体系委托.png"),
          title: "",
        },
        {
          img: require("../../assets/images/home体系落地.png"),
          title: "",
        },
        {
          img: require("../../assets/images/home过程优化.png"),
          title: "",
        },
        {
          img: require("../../assets/images/home考核标准.png"),
          title: "",
        },

        {
          img: require("../../assets/images/home题库服务.png"),
          title: "",
        },
      ],
      bookLists: [
        require("../../assets/images/book1.png"),
        require("../../assets/images/book2.png"),
        require("../../assets/images/book3.png"),
        require("../../assets/images/book4.png"),
        require("../../assets/images/book5.png"),
        require("../../assets/images/book6.png"),
        require("../../assets/images/book7.png"),
        require("../../assets/images/book8.png"),
        require("../../assets/images/book9.png"),
        require("../../assets/images/book10.png"),
      ],
      lineIndex: 0,
      superurl: [
        {
          url: "./book-all-detail",
          img: require("../../assets/images/book1.png"),
          p1: "ISO14001标准解说",
          p2: "本书系统的讲述了ISO14001标准的知识，从管理体系的基本概念、术语到管理体系的具体标准条款都有详细解说，是环境管理体系不可多得的教材类书籍",
        },
        {
          img: require("../../assets/images/book2.png"),
          p1: "管理体系数字化转型实践",
          p2: "本书提出数字经济下的数字体系概念，进而引申出先进体系的建设方法和思维方式，并提出一种普适性的数字体系建设方法，使企业数字化转型具有操作性，同时从结构设计开始的观点，介绍了从管理模式展开的经营路线和新型价值链两个关键的顶层结构。同时，由于数字体系与智能制造密不可分，本书给出了数字体系建设和转型的方向，并率先研究智能化制造与数字化体系的关系。",
        },
        {
          img: require("../../assets/images/book3.png"),
          p1: "过程方法",
          p2: "要打造一流的企业，就是要打造一流的过程。本书自始至终都在体现目前流行的“过程方法”的应用，并融入多年的实践经验与探索。相信对正要进行流程优化、完善和提升管理体系的企业有所帮助",
        },
        {
          img: require("../../assets/images/book4.png"),
          p1: "过程管理方法",
          p2: "要打造一流的企业，就是要打造一流的过程。本书自始至终都在体现目前流行的“过程方法”的应用，并融入多年的实践经验与探索。相信对正要进行流程优化、完善和提升管理体系的企业有所帮助！  ",
        },
        {
          img: require("../../assets/images/book5.png"),
          p1: "环境管理体系典型案例",
          p2: "本书为企业建立环境管理体系的各个阶段和过程都提供了丰富经典案例，书中案例均出自笔者辅导企业的真实案例，包括广州本田、东风本田发动机、金霸王、ICI太古漆等知名企业。",
        },
        {
          img: require("../../assets/images/book6.png"),
          p1: "环境管理体系内审员培训教程",
          p2: "本书为实施环境管理体系的企业内审员讲述了审核的基本知识和方法，以及分享了大量的审核案例。",
        },
        {
          img: require("../../assets/images/book7.png"),
          p1: "基于过程的质量管理体系策划",
          p2: "本书介绍了以顾客为关注焦点的企业，应该如何识别管理过程，并关切以过程为基础建立质量管理体系，最终建立起以顾客为导向的管理模式。",
        },
        {
          img: require("../../assets/images/book8.png"),
          p1: "基于流程和战略的组织设计",
          p2: "本书是介绍企业的如何基于企业战略方向和管理流程进行组织设计，以及组织设计的方法和原则，以促使企业达到战略目标。",
        },
        {
          img: require("../../assets/images/book9.png"),
          p1: "企业商业机密保护的最佳实践",
          p2: "本书是专门为广大中小型介绍如何快速、有效地建立一系列“小而精”的管理手段，以保护公司核心商业机密。这是我司长期为多家企业提供信息安全咨询的实践总结。",
        },
        {
          img: require("../../assets/images/book10.png"),
          p1: "质量环境一体化管理体系",
          p2: "本书是最早提及管理体系一体化的书籍，凭借笔者多年辅导企业的经验，观察到企业的潜在需求，为了更高效的运行多个管理体系，一体化体系是必然的结果，因此本书讲述了一体化体系的建立原则和案例研究。",
        },
      ],
      superurlIndex: 0,
      calleft: 0,
      logoList: [
        require("../../assets/images/logo/logo1.png"),
        require("../../assets/images/logo/logo2.png"),
        require("../../assets/images/logo/logo3.png"),
        require("../../assets/images/logo/logo4.png"),
        require("../../assets/images/logo/logo5.png"),
        require("../../assets/images/logo/logo6.png"),
        require("../../assets/images/logo/logo7.png"),
        require("../../assets/images/logo/logo8.png"),
        require("../../assets/images/logo/logo9.png"),
        require("../../assets/images/logo/logo10.png"),
        require("../../assets/images/logo/logo11.png"),
        require("../../assets/images/logo/logo12.png"),
        require("../../assets/images/logo/logo13.png"),
        require("../../assets/images/logo/logo14.png"),
        require("../../assets/images/logo/logo15.png"),
        require("../../assets/images/logo/logo16.png"),
        require("../../assets/images/logo/logo17.png"),
        require("../../assets/images/logo/logo18.png"),
        require("../../assets/images/logo/logo19.png"),
        require("../../assets/images/logo/logo20.png"),
        require("../../assets/images/logo/logo22.png"),
      ],
      // 视频
      videoList: [
        {
          bagroundImg: require("../../assets/images/bag3.png"),
          src: "/voide/MSA测量系统分析.mp4",
          name: "MSA测量系统分析",
          detail: "连续多年专业咨询辅导 连续多年连续多年专业咨询辅导...",
        },
        {
          bagroundImg: require("../../assets/images/bag3.png"),
          src: "/voide/FMEA.mp4",
          name: "VDA&AIAG-FMEA",
          detail: "连续多年专业咨询辅导 连续多年连续多年专业咨询辅导...",
        },
        {
          bagroundImg: require("../../assets/images/bag3.png"),
          src: "/voide/数字化时代.mp4",
          name: "数字化时代，让流程管理回归价值",
          detail: "连续多年专业咨询辅导 连续多年连续多年专业咨询辅导...",
        },
        {
          bagroundImg: require("../../assets/images/bag3.png"),
          src: "/voide/新版APQP.mp4",
          name: "新版APQP&CP控制计划的变化与应对",
          detail: "连续多年专业咨询辅导 连续多年连续多年专业咨询辅导...",
        },
      ],
      videoSource: "",
      isPlaying: false,
      showVideo: false,
      // 行业资讯
      consultName: "",
      consultList: [],
    };
  },
  animateScroll,
  mounted() {
    this.move();
    this.getNewList();
    this.changeDataList3Img(0);
  },
  methods: {
    skip() {
      window.open(
        this.$router.resolve({ path: "/specification" }).href,
        "_blank"
      );
    },
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
    toBookDetailPage(bookName) {
      this.$router
        .push("./book-all-detail?queryBookName=" + bookName)
        .catch(() => {});
    },
    //移动
    move() {
      this.timer = setInterval(this.starmove, 1400);
    },
    playVideo() {
      this.videoPlayer.play();
    },
    //开始移动
    starmove() {
      this.calleft -= 290;
      if (this.calleft < -2610) {
        //6×290
        this.calleft = 0;
        this.lineIndex = 0;
        return;
      }
      this.lineIndex += 1;
    },
    //鼠标悬停时停止移动
    stopmove() {
      clearInterval(this.timer);
    },
    //点击按钮左移
    zuohua() {
      this.calleft -= 290;
      if (this.calleft < -2610) {
        this.calleft = 0;
        this.lineIndex = 0;
        return;
      }
      this.lineIndex += 1;
    },
    //点击按钮右移
    youhua() {
      this.calleft += 290;
      if (this.calleft == 290) {
        this.calleft = 0;
        return;
      }
      this.lineIndex -= 1;
    },
    topage(index) {
      this.$router.push(
        this.dataList3[index].path + "?contactHomeIndex=" + index
      );
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },
    changeDataList3Img(index) {
      this.carouselIndex = index;
    },
    clickCarousel(index) {
      this.carouselIndex = index;
      this.$refs.showImgindex.setActiveItem(index);
    },
    // 视频播放
    openVideo(src) {
      window.open(baseURL.production + src);
    },
    // 行业资讯
    changeCarousel(index) {
      this.consultName = this.consultList[index].title;
    },
    getNewList() {
      get("/api/News/GetNewsByType?type=1").then((res) => {
        this.consultList = res.data
          .sort((a, b) => {
            return b.time.slice(0, 10) < a.time.slice(0, 10) ? -1 : 1;
          })
          .slice(0, 3);
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/style/FontSize.css";
@media screen and (min-width: 1200px) and (max-width: 1519px) {
  .videoItem {
    width: 1300px;
    div {
      img:nth-of-type(2) {
        top: 80px !important;
        width: 22% !important;
      }
      p:nth-of-type(1) {
        font-size: 16px !important;
      }
      p:nth-of-type(2) {
        font-size: 12px !important;
      }
    }
  }
  .industry-consulting {
    width: 1300px;
    .left {
      height: 480px !important;
      .el-carousel {
        height: 420px;
      }
      > p {
        font-size: 14px !important;
      }
    }
    .right {
      .title {
        border-bottom: 2px solid #d9d8d8;
        span {
          font-size: 14px !important;
          border-bottom: 4px solid #4a7ef1 !important;
        }
        .el-button {
          font-size: 12px !important;
        }
      }
      .div-box {
        .imgBox {
          width: 140px !important;
          height: 90px !important;
        }
        span:nth-of-type(1) {
          font-size: 14px !important;
        }
        span:nth-of-type(2) {
          font-size: 12px !important;
        }
        p {
          font-size: 12px !important;
        }
      }
    }
  }
}
.five-div {
  div {
    /deep/ .el-button {
      font-size: 16px !important;
      width: 160px !important;
      height: 45px !important;
      line-height: 45px !important;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .videoItem {
    width: 1000px;
    div {
      img:nth-of-type(2) {
        top: 60px !important;
        width: 20% !important;
      }
      p:nth-of-type(1) {
        font-size: 14px !important;
      }
      p:nth-of-type(2) {
        font-size: 10px !important;
      }
    }
  }
  .three-div {
    .carousel {
      flex-direction: column;
      .icon {
        display: flex;
        width: 13rem !important;
        text-align: center;
        justify-content: center;
        margin-bottom: 0.5rem;
        div:hover {
          padding: 0.06rem 0.2rem !important;
          margin: 0 !important;
        }
        span {
          padding: 0.06rem 0.2rem;
        }
        img {
          display: none;
        }
      }
    }
  }
  .four-div {
    width: 1200px !important ;
    .line {
      margin-top: -8vh !important;
      span {
        width: 20px !important;
        margin: 0 5px 5px;
      }
    }

    .Containt {
      width: 800px !important ;
      height: 300px !important;
      li {
        width: 200px !important;
        height: 280px !important;
        img {
          height: 120px !important;
        }
      }
      .p1 {
        font-size: 12px !important;
      }
      .p2 {
        font-size: 8px !important;
      }
      .iconleft {
        left: -42px !important;
      }
      .iconright {
        left: 780px !important;
      }
    }
    .Containt:before {
      left: -219px !important;
      width: 200px !important;
      height: 300px !important;
    }
    .Containt:after {
      width: 200px !important;
      left: 800px !important;
      height: 300px !important;
    }
  }
  .industry-consulting {
    width: 1050px;
    .left {
      height: 440px !important;
      .el-carousel {
        height: 380px;
      }
      > p {
        font-size: 12px !important;
      }
    }
    .right {
      .title {
        border-bottom: 1px solid #d9d8d8;
        span {
          font-size: 12px !important;
          border-bottom: 3px solid #4a7ef1 !important;
        }
        .el-button {
          font-size: 10px !important;
        }
      }
      .div-box {
        .imgBox {
          width: 120px !important;
          height: 80px !important;
        }
        span:nth-of-type(1) {
          font-size: 12px !important;
        }
        span:nth-of-type(2) {
          font-size: 10px !important;
        }
        p {
          font-size: 10px !important;
          width: 260px !important;
        }
      }
    }
  }
  .five-div {
    div {
      img {
        width: 100px !important;
      }
      /deep/ .el-button {
        font-size: 14px !important;
        width: 100px !important;
        height: 35px !important;
        line-height: 35px !important;
      }
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .video-box {
    height: auto;
  }
  .videoItem {
    width: 1200px;
  }
  .three-div {
    .carousel {
      flex-direction: column;
      .icon {
        display: flex;
        width: 13rem !important;
        text-align: center;
        justify-content: center;
        margin-bottom: 0.5rem;
        div:hover {
          padding: 0.06rem 0.2rem !important;
          margin: 0 !important;
        }
        span {
          padding: 0.06rem 0.2rem;
        }
        img {
          display: none;
        }
      }
    }
  }
  .industry-consulting {
    width: 1300px;
    height: auto !important;
    margin: 0 !important;
    .carouselName {
      display: block !important;
    }
    .left {
      display: none;
    }
  }
}
.home-page {
  .top-div {
    position: relative;
    height: 100vh;
    .bag {
      position: absolute;
      width: 100%;
    }
  }
  .big-title {
    text-align: center;
    padding-top: 3rem;
    p:nth-of-type(1) {
      color: white !important;
      font-weight: bold;
      letter-spacing: 3px;
    }
    p:nth-of-type(2) {
      opacity: 0.8;
      margin-top: 20px;
      letter-spacing: 2px;
      color: white !important;
    }
    > div {
      .el-button {
        border-radius: 5px !important;
        height: 0.6rem !important;
        width: 2rem !important;
        margin-top: 45px;
        background: #24569c !important;
        color: white;
        opacity: 0.8;
        font-size: 0.2rem !important;
        border: #24569c !important;
      }
      .el-button:hover {
        font-weight: bold;
      }
    }
  }

  .data-list2 {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #f4f8fb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > p {
      color: black;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .data-item {
      width: 66%;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      margin: 1% 17% 0 17%;
      > div {
        width: 30%;
        margin: 8px;
        padding-bottom: 40px;
        background-color: white;
        p:nth-of-type(1) {
          font-weight: bold;
        }
        p:nth-of-type(2) {
          margin-top: 5px;
          color: #858a91;
          padding: 0 50px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        img {
          width: 100px;
          height: 90px;
          padding-top: 30px;
        }
      }
      > div:hover {
        background-color: #5086fa !important;
        p {
          color: white !important;
        }
      }
    }
  }
  .three-div {
    width: 100%;
    height: 100vh;
    background-color: white;
    padding-bottom: 17px;
    > p {
      text-align: center;
      padding-top: 80px;
      font-weight: bold;
      color: black;
    }
    .carousel {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      align-items: center;

      .icon {
        width: 1.9rem;
        div:hover {
          background: #ebf9ff;
          margin-left: -10px;
          color: #49b4f7;
          font-weight: bold;
        }
        div {
          padding: 0.06rem 0;
          margin-bottom: 0.02rem;
          display: flex;
          align-items: center;
          border-radius: 5px;
          span {
            font-size: 0.18rem;
          }
          img {
            width: 0.5rem;
            margin: 0 10px;
          }
        }
      }
      .png {
        width: 10rem;
        margin-left: 0.35rem;
        p {
          font-size: 16px;
          text-align: start;
          margin-top: 10px;
        }
        img {
          width: 100%;
          cursor: pointer;
        }
      }
    }
    .carouselActive {
      background: #d1d8e5;
      font-weight: bold;
    }
  }
  .el-dialog__wrapper {
    overflow: hidden;
  }
  .video {
    text-align: center;
    video {
      width: 100%;
      padding-bottom: 20px;
      height: 60vh;
    }
  }
  .video-box {
    background: #f4f8fb;
    text-align: center;
    height: 100vh;

    > p {
      padding-top: 80px;
      font-weight: bold;
    }
    > div {
      background: #edf0f3;
      display: flex;
      margin: 2%;
      justify-content: center;
      padding: 60px 2%;
      div {
        position: relative;
        margin: 0 15px;
        text-align: center;
        border-radius: 10px;
        background: white;
        border: 1px solid silver;
        overflow: hidden;
      }
      img:nth-of-type(1) {
        width: 100%;
      }
      img:nth-of-type(2) {
        width: 25%;
        position: absolute;
        top: 80px;
        left: 39%;
        z-index: 999;
      }
      p:nth-of-type(1) {
        font-size: 20px;
        font-weight: bold;
        color: #4a7ef1;
        padding: 10px 0;
        margin: 0 20px;
      }
      p:nth-of-type(2) {
        font-size: 16px;
        color: #757a7f;
        padding-bottom: 10px;
        margin: 0 20px;
      }
    }
  }
  .four-div {
    width: 100%;
    height: 100vh;
    text-align: center;
    background-color: white;
    padding-bottom: 10px;
    .p {
      position: relative;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 80px;
      > p {
        font-weight: bold;
      }
      .el-button {
        position: absolute;
        // transform: scale(0.8);
        right: 10%;
        padding-top: 15px;
      }
    }
  }

  .five-div {
    width: 100%;
    height: 100vh;
    display: flex;
    background-image: url("../../assets/images/onepage8.png");
    justify-content: space-around;
    align-items: center;
    width: 100% 100vh;
    div {
      // height: 40vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-top: 80px;
        width: 180px;
      }
      > .el-button {
        background-color: #4c83f6;
        color: white;
        border-radius: 5px !important;
        margin-top: 40px;
        display: block;
      }
    }
    p {
      font-weight: bold;
    }
    p:nth-of-type(2),
    p:nth-of-type(3) {
      color: #2e84ec;
      margin-top: 20px;
    }
    img {
      width: 42%;
    }
    > img:nth-of-type(1) {
      margin-right: -40px;
    }
    > img:nth-of-type(2) {
      margin-left: -40px;
    }
  }
  .industry-consulting {
    height: 100vh;
    margin: 0 6%;
    background: #f4f8fb;
    padding-bottom: 20px;
    > p {
      font-weight: bold;
      padding-top: 50px;
      text-align: center;
    }
    .contain {
      background: #f6f6f6;
      display: flex;
      padding-top: 20px;
      margin-top: 20px;
      font-size: 15px;
      height: 500px;
      .left {
        // border: 1px solid #c4c3c3;
        position: relative;
        width: 50%;
        background: white;
        height: 530px;
        img {
          max-width: 100%;
          max-height: 100%;
          // width: 100%;
          // height: 100%;
          object-fit: cover;
          // border: 1px solid;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        p {
          text-align: start;
          padding: 20px;
          background: white;
          font-size: 18px;
        }
      }
      .right {
        width: 50%;
        padding-top: 5px;
        .carouselName {
          display: none;
        }
        > .title {
          display: flex;
          justify-content: space-between;
          border-bottom: 3px solid #d9d8d8;
          margin-left: 60px;
          margin-right: 20px;
          height: 40px;
          span {
            border-bottom: 5px solid #4a7ef1;
            padding-right: 15px;
            padding-left: 5px;
            height: 39px;
            line-height: 40px;
            cursor: default;
          }
          .el-button {
            margin-bottom: 5px;
          }
        }
        .div-box {
          margin-left: 60px;
          margin-right: 20px;
          margin-top: 15px;
          > div {
            background: white;
            display: flex;
            padding: 15px;
            margin-top: 10px;
            position: relative;
            .text {
              margin-left: 20px;
              flex: 1;
              div {
                margin-top: 5px;
                span {
                  cursor: default;
                }
                span:nth-of-type(2) {
                  font-size: 13px;
                  color: #7a7979;
                  position: absolute;
                  right: 10px;
                  cursor: default;
                }
              }
              p {
                width: 380px;
                margin-top: 15px;
                font-size: 13px;
                color: #7a7979;
                text-align: start;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
        .imgBox {
          width: 160px;
          height: 110px;
          overflow: hidden;
          position: relative;
          img {
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  .contact-us {
    background: #222c3f;
    color: white;
    padding: 20px 10%;
    flex: 1;
    .phone {
      padding-bottom: 5px;
      border-bottom: 1px solid #50769c;
      font-size: 15px;
      > div:nth-of-type(2) {
        width: 100%;
        text-align: end;
      }
    }
    .other-info {
      display: flex;
      margin-top: 20px;
      font-size: 14.5px;
      > div:nth-of-type(1) {
        flex: 2;
      }
      > div:nth-of-type(2) {
        flex: 1;
      }
      > .jpg {
        text-align: end;
        flex: 1;
        img {
          margin-right: 15px;
          width: 70px;
          height: 70px;
          margin-bottom: 8px;
        }
      }
      .infoBox {
        margin-top: 6px;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        img {
          width: 30px;
          height: 30px;
          margin-right: 5px;
        }
        a {
          color: skyblue;
        }
      }
    }
  }
  .back_add .line {
    margin-top: 15px;
    span {
      width: 32px;
      height: 2.5px;
      background-color: silver;
      display: inline-block;
      margin: 30px 5px 0 5px;
    }
  }
  .activeLine {
    background-color: #4d7fed !important;
  }

  .threeImg {
    background: white;
    height: 360px;
    overflow: hidden;
  }

  .threeImg .Containt ul {
    margin: 0 auto;
    position: absolute;
    left: 0px;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
  }

  .threeImg .Containt ul li {
    position: relative;
    width: 250px;
    height: 350px;
    margin-right: 40px;
    margin-top: 5px;
    float: left;
    background-color: white;
    box-shadow: 0 1px 10px -2px #9ca6ad;
    list-style: none;
    border-radius: 5px;
  }
  .threeImg .Containt ul li::before {
    position: absolute;
    content: "1";
    width: 8px;
    height: 35px;
    background-color: #4c7df3;
    color: #4c7df3;
    left: -4px;
    bottom: 110px;
    z-index: 999;
  }
  .threeImg .Containt ul li img {
    height: 180px;
    margin-top: 25px;
  }

  .Containt {
    position: relative;
    width: 1200px; //(4*250(li的宽度)+5(左右对称为5个边距)*40(li的边距))
    height: 355px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  .Containt::before {
    content: "";
    position: absolute;
    z-index: 9999;
    left: -200px;
    width: 220px;
    height: 380px;
    background-color: #ffffff;
    opacity: 0.7;
  }
  .Containt::after {
    content: "";
    position: absolute;
    z-index: 9999;
    right: -200px;
    width: 220px;
    height: 380px;
    background-color: #ffffff;
    opacity: 0.7;
  }
  .threeImg .Containt ul li .p1 {
    padding: 5px 10px;
    font-size: 15px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .threeImg .Containt ul li .p2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    padding: 0 10px;
    line-height: 20px;
    color: #9a9ea8;
  }
  .iconleft {
    position: absolute;
    z-index: 99999;
    left: -5px;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border: 1px solid #d1d1d172;
    background-color: white;
    border-radius: 50px;
    box-shadow: 0 1px 10px 15px white;
    i {
      font-size: 14px;
      color: #818080;
      font-weight: bold;
    }
  }

  .iconright {
    position: relative;
    left: 1165px;
    z-index: 99999;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border: 1px solid #d1d1d172;
    background-color: white;
    border-radius: 50px;
    box-shadow: 0 1px 10px 15px white;
    i {
      font-size: 14px;
      color: #818080;
      font-weight: bold;
    }
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    // background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    // background-color: #d3dce6;
  }
  ::v-deep .el-carousel__indicators {
    display: none !important;
  }
}
</style>